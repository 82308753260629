// This ugly little beauty came straight from StackOverflow
// ToDo: [MW-304] create a "flatten rich content content" helper for schema markup use case
function findAllByKey(obj, keyToFind) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    try {
      // eslint-disable-next-line no-nested-ternary
      return key === keyToFind
        ? acc.concat(value)
        : typeof value === "object"
        ? acc.concat(findAllByKey(value, keyToFind))
        : acc;
    } catch (e) {
      return null;
    }
  }, []);
}

export default function generateQuestionAnswerSchemaMarkup(body) {
  // eslint-disable-next-line array-callback-return
  const faqItems = body.map((item) => {
    const questionText = item.title;

    const answerText = findAllByKey(item.body.content, "text").join(" ");

    const faqObject = {
      "@type": "Question",
      name: questionText,
      acceptedAnswer: {
        "@type": "Answer",
        text: answerText,
      },
    };

    return faqObject;
  });
  const data = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [...faqItems],
  };
  return data;
}
