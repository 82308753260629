import { createUrlPathFromArray } from "~utils/create_url_path_from_array/create_url_path_from_array";
import { LANGUAGE_REGEX } from "~utils/regex_strings/regex_language_country_code.js/regex_language_country_code";
import { removeDefaultLanguagePrefix } from "~utils/url_utils";
import { ensureTrailingSlash } from "~utils/url_utils/ensureTrailingSlash/ensureTrailingSlash";

export default function getObBreadcrumbsSchemaMarkup({
  origin,
  basePath,
  title,
}) {
  if (!title || !basePath) {
    return {};
  }

  const sanitisedBasePath = removeDefaultLanguagePrefix(basePath);
  const sanitisedBasePathWithTrailingSlash =
    ensureTrailingSlash(sanitisedBasePath);

  let lang = null;
  const matches = sanitisedBasePath.match(LANGUAGE_REGEX);
  if (matches && matches[1]) {
    // eslint-disable-next-line prefer-destructuring
    lang = matches[1];
  }

  let localisedOrigin = origin;

  if (lang !== "en-gb") {
    localisedOrigin = createUrlPathFromArray([origin, lang]);
  }
  const openBankingGuidePath = createUrlPathFromArray([
    origin,
    sanitisedBasePathWithTrailingSlash,
  ]);

  const itemListElement = [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: localisedOrigin,
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "Open banking guide",
      item: openBankingGuidePath,
    },
    {
      "@type": "ListItem",
      position: 3,
      name: title,
    },
  ];

  const data = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement,
  };

  return data;
}
