import React, { useMemo, useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import ChapterPrevNextButtons from "~components/molecules/chapter_prev_next_buttons/chapter_prev_next_buttons";
import ContentCoverArtwork from "~components/molecules/content_cover_artwork/content_cover_artwork";
import HorizontalDivider from "~components/molecules/horizontal_divider/horizontal_divider";
import LongFormTextItem from "~components/molecules/long_form_text_item/long_form_text_item";
import RscHbCtaNewsletter from "~components/molecules/rsc_hb_cta/rsc_hb_cta_newsletter/rsc_hb_cta_newsletter";
import RscHbInPageContactForm from "~components/molecules/rsc_hb_in_page_contact_form/rsc_hb_in_page_contact_form";
import RscHbPageHeader from "~components/molecules/rsc_hb_page_header/rsc_hb_page_header";
import TOCNav from "~components/organisms/toc_nav/toc_nav";
import JsonLd from "~components/utils/json_ld/json_ld";
import useIntersect from "~hooks/use_intersect/use_intersect";
import usePageData from "~hooks/use_page_data/use_page_data";
import useSiteUrl from "~hooks/use_site_url/use_site_url";
import { ASSET, BLOK_ARRAY, MEDIA, STORYBLOK_ENTRY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import generateQuestionAnswerSchemaMarkup from "./helper_functions/generate_question_answer_schema_markup/generate_question_answer_schema_markup";
import getObBreadcrumbsSchemaMarkup from "./helper_functions/get_ob_breadcrumbs_schema_markup/get_ob_breadcrumbs_schema_markup";
import {
  openBankingWrapperStyle,
  outerWrapperStyle,
} from "./template_open_banking_guide.module.scss";

export default function TemplateOpenBankingGuide(props) {
  const { story, pageContext, entryAssets, data } = usePageData(props);

  const origin = useSiteUrl();

  const allGuideChapters = data?.guides?.nodes || {};

  const {
    breadcrumbsItems,
    contactForm,
    next,
    previous,
    subNavData,
    UIStrings,
    resourceHubCtaConfig,
    lang,
    pagePath,
    navigationConfig,
    currentChapterIndex,
  } = pageContext;

  const { title, body, heroArtwork, subtitle } = story;

  const { ctaNewsletter } = resourceHubCtaConfig || {};

  const [activeItem, setActiveItem] = useState(title);

  const [ref, inView] = useIntersect({ rootMargin: "-25% 0% -50% 0%" });

  const basePath = props?.data?.story?.full_slug?.split("/")[1];

  const { uiAllChaptersTitle } = UIStrings || {};
  const { openBankingGuideTitle } = navigationConfig || {};

  const schemaMarkupData = useMemo(() => {
    return generateQuestionAnswerSchemaMarkup(body);
  }, [body]);

  const obBreadcrumbsSchemaMarkupData = useMemo(() => {
    return getObBreadcrumbsSchemaMarkup({
      origin,
      basePath,
      title,
    });
  }, [basePath, origin, title]);

  return (
    <TemplateGlobalEntry
      subNavData={subNavData}
      entryAssets={entryAssets}
      {...props}
      headerColor="blue"
    >
      <JsonLd data={schemaMarkupData} />
      <JsonLd data={obBreadcrumbsSchemaMarkupData} />

      <div className={outerWrapperStyle}>
        <TOCNav
          collectionTitle={openBankingGuideTitle}
          pagePath={pagePath}
          currentChapterIndex={currentChapterIndex}
          chapters={allGuideChapters}
          sections={body}
          isVisible={inView.isIntersecting}
          activeItem={activeItem}
          allChaptersTitle={uiAllChaptersTitle}
        />

        <div ref={ref} className={openBankingWrapperStyle}>
          <Contain small>
            <RscHbPageHeader
              breadcrumbsItems={breadcrumbsItems}
              title={title}
              description={subtitle}
            />

            <ContentCoverArtwork cover={heroArtwork} />

            <HorizontalDivider />

            {/* ———— GUIDE CONTENT ————— */}
            {body.map((child) => {
              return (
                <LongFormTextItem
                  callbackWhenInView={setActiveItem}
                  key={child.title}
                  {...child}
                />
              );
            })}
          </Contain>

          <Contain>
            <ChapterPrevNextButtons
              previousTitle={UIStrings?.uiPreviousTitle}
              previousDescription={previous?.title}
              nextTitle={UIStrings?.uiNextTitle}
              nextDescription={next?.title}
              next={next}
              previous={previous}
            />
            <HorizontalDivider />
          </Contain>

          <Contain small>
            <RscHbCtaNewsletter {...ctaNewsletter} />
          </Contain>
        </div>
      </div>
      <RscHbInPageContactForm lang={lang} {...contactForm} />
    </TemplateGlobalEntry>
  );
}

TemplateOpenBankingGuide.propTypes = {
  data: PropTypes.shape({
    assets: PropTypes.shape({
      nodes: PropTypes.arrayOf(ASSET),
    }),
    guides: PropTypes.shape({}),
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    body: BLOK_ARRAY,
    breadcrumbsItems: PropTypes.arrayOf(PropTypes.shape({})),
    contactForm: PropTypes.shape({
      id: PropTypes.string,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    }),
    lang: PropTypes.string,
    heroArtwork: MEDIA,
    next: STORYBLOK_ENTRY,
    previous: STORYBLOK_ENTRY,
    UIStrings: PropTypes.shape({
      uiNextTitle: PropTypes.string,
      uiPreviousTitle: PropTypes.string,
    }),
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

// TODO: get page specific metadata here
export const query = graphql`
  fragment openBankingStory on Query {
    story: storyblokEntry(id: { eq: $id }) {
      content
      full_slug
      group_id
      alternates {
        full_slug
      }
    }
  }
  fragment allOpenBankingGuides on Query {
    guides: allStoryblokEntry(
      filter: { id: { in: $allOpenBankingGuideIds } }
      sort: { fields: sort_by_date }
    ) {
      nodes {
        id
        full_slug
        field_title_string
        field_subtitle_string
      }
    }
  }
  fragment allOBGuideAssets on Query {
    assets: allFile(filter: { id: { in: $allAssets } }) {
      nodes {
        id
        publicURL
        fields {
          aspectRatio
        }
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  query OpenBankingQuery(
    $id: String
    $langRegex: String
    $allAssets: [String]
    $allOpenBankingGuideIds: [String]
  ) {
    ...story
    ...allOBGuideAssets
    ...allOpenBankingGuides
    ...global
  }
`;
